export const selectors = {
  actionLink: '.cmp-teaser__action-link',
  teaserLink: '.cmp-teaser__link',
  image: '.cmp-image',
  imageImg: '.cmp-image__image',
  teaserContentContainer: '.cmp-teaser__content-container',
  teaserContentOverlay: '.cmp-teaser__content-overlay',
  teaserContentDrag: '.cmp-teaser__content-drag',
  teaserContent: '.cmp-teaser__content',
};

export const classes = {
  offsetVariation: 'batcom-teaser-offset',
  stageVariation: 'batcom-teaser-corp-stage',
  verticalVariation: 'batcom-teaser-corp--vertical',
  horizontalVariation: 'batcom-teaser-corp--horizontal',
  isClickable: 'cmp-teaser--clickable',
  curtain: 'batcom-teaser-corp-curtain',
  curtainFixed: 'batcom-teaser-corp-curtain--fixed',
  curtainLimit: 'batcom-teaser-corp-curtain--limit',
  imageHidden: 'cmp-image__image--hidden',
  hasBackground: 'cmp-image--has-background',
  gloQRTeaser: 'batcom-gloqr-teaser',
  showContent: 'cmp-teaser__content-show',
  draggingContent: 'cmp-teaser__content-dragging',
}
